import { isBefore, isWithinInterval } from "date-fns";
import { getCookie } from "./cookies";
import { arrOfObjToItems } from "./filterHelper";

export const isLoggedIn = () => !!getCookie("token");

export const uniqArray = (arr) =>
  arr && arr.filter((value, index, self) => self.indexOf(value) === index);

export const notNullArrValues = (arr) =>
  arr && arr.filter((item) => item !== null);

export const getUniqueElements = (arr1, arr2) => {
  return arr1?.filter((element) => !arr2?.includes(element));
};

export const getBrandCode = (name) => name?.slice(0, 2).toUpperCase();

export const arrToOptions = (arr, upper = false) =>
  arr?.map((opt) => ({
    label: upper ? opt.toUpperCase() : opt.toLowerCase(),
    value: upper ? opt.toUpperCase() : opt.toLowerCase(),
  }));

export const arrToOptionsRaw = (arr) =>
  arr?.map((opt) => ({
    label: opt,
    value: opt,
  }));

export const filterByFieldValue = (arr, field, value) =>
  arr?.filter((item) => item[field] === value);

export const objToOptions = (obj) =>
  Object.keys(obj).map((key) => ({
    label: obj[key],
    value: key,
  }));

export const arrOfObjToObj = (arr, obj) =>
  arr.forEach((item) => (obj[Object.keys(item)[0]] = Object.values(item)[0]));

export const arrOfImgToObjByPath = (arr) => {
  const obj = {};
  arr.forEach((item) => (obj[item.path] = item));
  return obj;
};

export const objToArrOfObj = (obj: { [k: string]: string }) =>
  Object.entries(obj).map((obj) => ({
    [obj[0]]: obj[1],
  }));

export const someObjPropsEmpty = (obj: { [k: string]: string }) =>
  Object.keys(obj).some((key) => key.length === 0) ||
  Object.values(obj).some((val) => val.length === 0);

export const arrToObjByObjData = (arr, objData) =>
  arr?.reduce((obj, item) => Object.assign(obj, { [item]: objData[item] }), {});

export const refreshOptions = (arr, obj) =>
  arr.filter((item) => !Object.keys(obj).includes(item.value)); // If item already selected remove it from available option array

export const cleanObjFromEmptyVal = (obj) => {
  const cleanObj = { ...obj };
  for (let propName in cleanObj) {
    if (cleanObj[propName] === "" || cleanObj[propName] === undefined) {
      delete cleanObj[propName];
    }
  }
  return cleanObj;
};

export const objectFromArrayWithIndices = (obj, value) =>
  obj?.reduce((acc, curr: any, index) => {
    acc[curr] = value ?? index + 1;
    return acc;
  }, {});

export const getImgUrl = (cdn, path) => (!!cdn && !!path ? cdn + path : "");
export const getImgPath = (cdn, url) => url.split(cdn)[1];

export const getKeyFromArgs = (...arg) => arg.join(".");

export const getObjValByPath = (
  obj: {},
  pathArr: string[],
  defaultVal: any = null
) => {
  if (typeof obj !== "object" || obj === null) return defaultVal;

  let current = obj;

  for (let i = 0; i < pathArr.length; i++) {
    if (!current[pathArr[i]]) return defaultVal;
    current = current[pathArr[i]];
  }

  return current;
};

export const getStorageImagesData = (imagesObj, fileStorageList) => {
  const result = {};
  for (const [key, value] of Object.entries(imagesObj)) {
    result[key] = fileStorageList.filter(({ path }) => path === value)[0];
  }
  return result;
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const updateOrderListPosition = (
  orderList,
  originOrderMap,
  filter,
  updateHandler
) => {
  const result = { ...originOrderMap };
  for (const [key, value] of Object.entries(orderList)) {
    result[key] && (result[key][filter.value] = value);
  }

  for (const [key] of Object.entries(result)) {
    if (!Object.keys(result[key]).length) {
      delete result[key];
    }
  }

  updateHandler(result);
};

export const sortDisplayPosition = (a, b, filter) =>
  a.display_position[filter] - b.display_position[filter];

export const sortByPosition = (a, b) => a.position - b.position;

export const sortByPriority = (priority, name) => (a, b) => {
  const priorityA = priority.findIndex((field) => a[field]);
  const priorityB = priority.findIndex((field) => b[field]);

  if (priorityA === priorityB) {
    return a[name].localeCompare(b[name]);
  }

  return priorityA - priorityB;
};

export const responseFileDownload = (data, filename) => {
  if (data) {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();

    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      link.remove();
    }, 250);
  }
};

export const dateUTCParser = (date) => new Date(Date.parse(`${date}Z`));

export const convertDateToUTC = (date) =>
  new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );

export const checkForInterval = (item, currentDate) => {
  if (!item.show_from || !item.show_to) {
    return;
  }

  return isWithinInterval(currentDate, {
    start: new Date(item.show_from),
    end: new Date(item.show_to),
  });
};

export const checkForSchedule = (item, currentDate) => {
  const isEnabled = checkForInterval(item, currentDate);
  const upComing = isBefore(currentDate, new Date(item.show_from));

  return {
    isSet: item.show_from && item.show_to,
    isEnabled: isEnabled,
    title: isEnabled ? "Ongoing" : upComing ? "Upcoming" : "Passed",
  };
};

export const prepareDataMap = (data) => {
  let mapVal = {};
  data.forEach((item) => {
    mapVal[item.id] = { position: null };
  });
  return mapVal;
};

export const setCheckboxList = (
  val,
  list,
  setList,
  selectedList,
  fieldName,
  filterField = "id"
) => {
  const checkboxVal = val.target.value;
  let updatedList;

  if (arrOfObjToItems(selectedList, filterField).includes(checkboxVal)) {
    const tmpList = arrOfObjToItems(selectedList, filterField).filter(
      (id) => id !== checkboxVal
    );
    updatedList = list.filter(
      (item) => tmpList.indexOf(item[filterField]) > -1
    );
  } else {
    updatedList = [
      ...selectedList,
      list.filter((item) => item[filterField] === checkboxVal)[0],
    ];
  }

  setList(fieldName, updatedList);
};

export const notEmptyArr = (arr) => !!arr?.length;

export const insertIf = (condition, ...elements) =>
  condition ? [...elements] : [];

export const sortAlphabeticByField = (a, b, fieldName) =>
  a?.[fieldName].toLowerCase() > b?.[fieldName].toLowerCase()
    ? 1
    : b?.[fieldName].toLowerCase() > a?.[fieldName].toLowerCase()
    ? -1
    : 0;

export const sortAlphabetic = (a, b) =>
  a.toLowerCase() > b.toLowerCase()
    ? 1
    : b.toLowerCase() > a.toLowerCase()
    ? -1
    : 0;

export const compareObjects = (obj1, obj2) =>
  JSON.stringify(obj1) === JSON.stringify(obj2);

export const compareArrays = (arr1, arr2) => {
  if (arr1.length !== arr2.length) return false;
  arr1.forEach((item, index) => {
    if (item !== arr2[index]) {
      return false;
    }
  });

  return true;
};

export const getNestedObjProps = (
  path: string, // ex: "obj.field.name" display_condition.amount_from. const obj = { display_condition: { amount_from: { EUR: 123, USD: 456 } } }
  obj
) => path.split(".").reduce((prev, curr) => prev && prev[curr], obj);

export const isVideoFileType = (filename) => {
  const getExtension = (filename) => {
    const parts = filename.split(".");
    return parts[parts.length - 1].toLowerCase();
  };

  switch (getExtension(filename)) {
    // case "gif":
    case "m4v":
    case "avi":
    case "wmv":
    case "mpg":
    case "mkv":
    case "mp4":
    case "webm":
      // etc
      return { ext: getExtension(filename), isVideo: true };
  }
  return { isVideo: false };
};

export const checkTransKeyExist = (list, key) =>
  list.map((el) => el.key).indexOf(key?.trim()) > -1;

export const checkFilterCondition = (filter) => {
  if (!filter) return false;

  if (filter.value && filter.value !== "") {
    return true;
  }

  if (filter.display_position && !!filter.display_position.value) {
    const otherKeys = Object.keys(filter).filter(
      (key) => key !== "display_position"
    );

    const areOtherPropertiesEmpty = otherKeys.every((key) => {
      const value = filter[key].value;
      return (
        value === undefined ||
        value === "" ||
        (typeof value === "object" && Object.keys(value).length === 0)
      );
    });

    if (areOtherPropertiesEmpty) {
      return true;
    }
  }

  return false;
};
