import { useCallback, useLayoutEffect } from "react";
import { useSSOLogout } from "../../modules/Auth/api/apiHooks";
import { shallowEqual } from "react-redux";
import { useSelector } from "react-redux";
import { ApplicationState } from "../reducers";
import config from "../config";
import { handleSSOErrorByStatus } from "../hooks/useApiRequest";

const loadSSOWidget = (config: SSOWidgetSettings) => {
  try {
    return new Promise(function (_, reject) {
      const isAlreadyAdded = document.getElementById("sso-widget-script");
      if (isAlreadyAdded) return;

      const script = document.createElement("script");
      script.src = `https://sso-widget.stage.gamingsphere.xyz/index.js?timestamp=${Date.now()}`;
      script.async = true;
      script.id = "sso-widget-script";
      script.onload = function () {
        if (typeof window.initWidget === "function") {
          window.initWidget(config);
        }
      };
      script.onerror = function () {
        reject("SSO Widget: Failed to load the script");
      };
      document.head.appendChild(script);
    });
  } catch (err) {}
};

export const useSSOWidget = (cfg: Partial<SSOWidgetSettings>) => {
  const { rootSelector } = cfg || {};
  const { mutate: onLogout } = useSSOLogout();
  const token = useSelector(
    (state: ApplicationState) => state.auth.sso_token,
    shallowEqual
  );

  const onError = useCallback((err) => {
    handleSSOErrorByStatus(err.status);
  }, []);

  useLayoutEffect(() => {
    if (token) {
      loadSSOWidget({
        rootSelector,
        token,
        onLogout,
        onError,
        baseApiUrl: config.SSO_BASE_API_URL,
      });
    }
  }, [token, onLogout, onError, rootSelector]);
};
